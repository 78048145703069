export default function getFileDownloadLink(params) {
    this.rest.post('getFileDownloadLink', {recordID: params.id, module: params.module}, (data) => {
        try {
            if(data.status === false){
                const translateText = this.translate(data.message.text ?? data.text);
                console.error('getFileDownloadLink Error', translateText)
                this.error(translateText)
            }
            const link = data?.message?.data?.downloadLink;
            if(!link){
                // this shouldn't happen, everything should be handled in catch, but what if...
                this.error("Missing link");
                return;
            }
            const url = encodeURIComponent(link);
            window.open(`${this?.param?.server}/getFileViaLink?downloadLink=${url}`, '_blank');
        } catch (e) {
            const translateText = this.translate(data.message.text ?? data.text);
            console.error('getFileDownloadLink Error', translateText, e?.message)
            this.error(translateText)
        }
    });
}
