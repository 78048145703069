import $ from "jquery";

export default class rest {
  constructor(parent) {
    this.url = parent.param.server;
    this.token = null;
    this.parent = parent;
    this.xhr = null;
  }

  post(action, data, complete, cancelPrevisouRequest = true) {
    this.call(
      action,
      "POST",
      data,
      function (msg) {
        complete(msg);
      },
      cancelPrevisouRequest
    );
  }
  get(action, complete, cancelPrevisouRequest = true) {
    this.call(
      action,
      "GET",
      null,
      function (msg) {
        complete(msg);
      },
      cancelPrevisouRequest
    );
  }
  delete(action, data, complete) {
    this.call(action, "DELETE", data, function (msg) {
      complete(msg);
    });
  }

  call(action, method, data, complete, cancelPrevisouRequest) {
    var auth = this.getAuth();
    var self = this;
    if (this.xhr != null && cancelPrevisouRequest == true) {
      this.xhr.abort();
    }
    // if (action !== 'trns?language=cs_CZ') {
    //   action = action + '?XDEBUG_SESSION_START=1';
    // }
    this.xhr = $.ajax({
      method: method,
      contentType: "application/json",
      url: self.url + "/" + action,
      // url: self.url + "/" + action+"?XDEBUG_SESSION_START=1",

      data: JSON.stringify(data),
      headers: auth,
      success: (data) => {
        //zobrazeni fatalnejsich chyb, ktere mohou prerusit routovani atd.
        if (typeof data === "object" && data.status === "error") {
          if (data.reason != undefined) {
            self.parent.dsClear();
            if (self.parent.dataGet("rightPanel/show") == true) {
              self.parent.dsAdd("set", "rightPanel/content", "error");
              self.parent.dsAdd("set", "conf/load", false);
              self.parent.dsAdd("set", "rightPanel/data", {
                state: false,
                type: data.reason,
              });
            } else {
              self.parent.dsAdd("set", "conf/view", "error");
              self.parent.dsAdd("set", "conf/load", false);
              self.parent.dsAdd("set", "view", {
                state: false,
                type: data.reason,
              });
            }

            self.parent.dsProcess();
          } else {
            self.parent.unLoad();
            self.parent.error(self.parent.translate(data.msg || data.message));
          }
        }

        complete(data);

        //zobrazeni dalsich chybovych hlasek
        if (typeof data === "object" && data.status === "warning") {
          setTimeout(function () {
            self.parent.error(self.parent.translate(data.msg || data.message));
          }, 2500);
        }
      },
      error: (XMLHttpRequest, textStatus, errorThrown) => {
        if (
          (XMLHttpRequest.status == "401" || XMLHttpRequest.status == 0) &&
          XMLHttpRequest.statusText != "abort"
        ) {
          if(action === "getNotifyCount" || action === "recalcTopRecent"){
            return;
          }
          
          if (!window.location.hash.startsWith('#login')) {
            if (XMLHttpRequest.status == "401") {

              window.location.hash = "#login";

              this.get("trns", data => {
                self.parent.app_strings = data.lang;
                self.parent.renderReact();
              });
            } else {
              self.parent.error(self.parent.translate("LBL_SERVER_ERROR"));
              self.parent.unLoad();
            }
          } else {
            clearInterval(this.parent.notificationInterval);
            self.parent.routeLogin();
          }
        }
          if(XMLHttpRequest?.status == '500'){
              const responseObject = XMLHttpRequest?.responseJSON ?? {};
              const responseData = responseObject?.errorMessage
              if(responseData?.text){
                  self.parent.error(self.parent.translate(responseData.text));
                  console.error('ERROR - response data', responseData.text, responseData?.data)
              }
          }
      },
    });
  }
  getAuth() {
    var token = this.getCookie("sID");
    var auth = {
      sID: token,
    };

    if (this.parent.deviceType != undefined) {
      auth["device"] = this.parent.deviceType;
    }
    auth["deviceDesc"] = JSON.stringify(this.getDevice())
    return auth;
  }
  setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + "; " + expires;
  }
  getCookie(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == " ") c = c.substring(1);
      if (c.indexOf(name) == 0) return c.substring(name.length, c.length);
    }
    return null;
  }
  getDevice() {
    if (this.browserInfo != null) {
        return this.browserInfo;
    }
    var unknown = 'Unknown';

    // screen
    var screenSize = '';
    if (screen.width) {
        var width = (screen.width) ? screen.width : '';
        var height = (screen.height) ? screen.height : '';
        screenSize += '' + width + " x " + height;
    }

    //browser
    var nVer = navigator.appVersion;
    var nAgt = navigator.userAgent;
    var browser = navigator.appName;
    var version = '' + parseFloat(navigator.appVersion);
    var majorVersion = parseInt(navigator.appVersion, 10);
    var nameOffset, verOffset, ix;

    // Opera
    if ((verOffset = nAgt.indexOf('Opera')) != -1) {
        browser = 'Opera';
        version = nAgt.substring(verOffset + 6);
        if ((verOffset = nAgt.indexOf('Version')) != -1) {
            version = nAgt.substring(verOffset + 8);
        }
    }
    // MSIE
    else if ((verOffset = nAgt.indexOf('MSIE')) != -1) {
        browser = 'Microsoft Internet Explorer';
        version = nAgt.substring(verOffset + 5);
    }

    //IE 11 no longer identifies itself as MS IE, so trap it
    //http://stackoverflow.com/questions/17907445/how-to-detect-ie11
    else if ((browser == 'Netscape') && (nAgt.indexOf('Trident/') != -1)) {

        browser = 'Microsoft Internet Explorer';
        version = nAgt.substring(verOffset + 5);
        if ((verOffset = nAgt.indexOf('rv:')) != -1) {
            version = nAgt.substring(verOffset + 3);
        }

    }

    // Chrome
    else if ((verOffset = nAgt.indexOf('Chrome')) != -1) {
        browser = 'Chrome';
        version = nAgt.substring(verOffset + 7);
    }
    // Safari
    else if ((verOffset = nAgt.indexOf('Safari')) != -1) {
        browser = 'Safari';
        version = nAgt.substring(verOffset + 7);
        if ((verOffset = nAgt.indexOf('Version')) != -1) {
            version = nAgt.substring(verOffset + 8);
        }

        // Chrome on iPad identifies itself as Safari. Actual results do not match what Google claims
        //  at: https://developers.google.com/chrome/mobile/docs/user-agent?hl=ja
        //  No mention of chrome in the user agent string. However it does mention CriOS, which presumably
        //  can be keyed on to detect it.
        if (nAgt.indexOf('CriOS') != -1) {
            //Chrome on iPad spoofing Safari...correct it.
            browser = 'Chrome';
            //Don't believe there is a way to grab the accurate version number, so leaving that for now.
        }
    }
    // Firefox
    else if ((verOffset = nAgt.indexOf('Firefox')) != -1) {
        browser = 'Firefox';
        version = nAgt.substring(verOffset + 8);
    }
    // Other browsers
    else if ((nameOffset = nAgt.lastIndexOf(' ') + 1) < (verOffset = nAgt.lastIndexOf('/'))) {
        browser = nAgt.substring(nameOffset, verOffset);
        version = nAgt.substring(verOffset + 1);
        if (browser.toLowerCase() == browser.toUpperCase()) {
            browser = navigator.appName;
        }
    }
    // trim the version string
    if ((ix = version.indexOf(';')) != -1) version = version.substring(0, ix);
    if ((ix = version.indexOf(' ')) != -1) version = version.substring(0, ix);
    if ((ix = version.indexOf(')')) != -1) version = version.substring(0, ix);

    majorVersion = parseInt('' + version, 10);
    if (isNaN(majorVersion)) {
        version = '' + parseFloat(navigator.appVersion);
        majorVersion = parseInt(navigator.appVersion, 10);
    }

    // mobile version
    var mobile = /Mobile|mini|Fennec|Android|iP(ad|od|hone)/.test(nVer);

    // cookie
    var cookieEnabled = (navigator.cookieEnabled) ? true : false;

    if (typeof navigator.cookieEnabled == 'undefined' && !cookieEnabled) {
        document.cookie = 'testcookie';
        cookieEnabled = (document.cookie.indexOf('testcookie') != -1) ? true : false;
    }

    // system
    var os = unknown;
    var clientStrings = [
        { s: 'Windows 3.11', r: /Win16/ },
        { s: 'Windows 95', r: /(Windows 95|Win95|Windows_95)/ },
        { s: 'Windows ME', r: /(Win 9x 4.90|Windows ME)/ },
        { s: 'Windows 98', r: /(Windows 98|Win98)/ },
        { s: 'Windows CE', r: /Windows CE/ },
        { s: 'Windows 2000', r: /(Windows NT 5.0|Windows 2000)/ },
        { s: 'Windows XP', r: /(Windows NT 5.1|Windows XP)/ },
        { s: 'Windows Server 2003', r: /Windows NT 5.2/ },
        { s: 'Windows Vista', r: /Windows NT 6.0/ },
        { s: 'Windows 7', r: /(Windows 7|Windows NT 6.1)/ },
        { s: 'Windows 8.1', r: /(Windows 8.1|Windows NT 6.3)/ },
        { s: 'Windows 8', r: /(Windows 8|Windows NT 6.2)/ },
        { s: 'Windows NT 4.0', r: /(Windows NT 4.0|WinNT4.0|WinNT|Windows NT)/ },
        { s: 'Windows ME', r: /Windows ME/ },
        { s: 'Android', r: /Android/ },
        { s: 'Open BSD', r: /OpenBSD/ },
        { s: 'Sun OS', r: /SunOS/ },
        { s: 'Linux', r: /(Linux|X11)/ },
        { s: 'iOS', r: /(iPhone|iPad|iPod)/ },
        { s: 'Mac OS X', r: /Mac OS X/ },
        { s: 'Mac OS', r: /(MacPPC|MacIntel|Mac_PowerPC|Macintosh)/ },
        { s: 'QNX', r: /QNX/ },
        { s: 'UNIX', r: /UNIX/ },
        { s: 'BeOS', r: /BeOS/ },
        { s: 'OS/2', r: /OS\/2/ },
        { s: 'Search Bot', r: /(nuhk|Googlebot|Yammybot|Openbot|Slurp|MSNBot|Ask Jeeves\/Teoma|ia_archiver)/ }
    ];
    for (var id in clientStrings) {
        var cs = clientStrings[id];
        if (cs.r.test(nAgt)) {
            os = cs.s;
            break;
        }
    }

    var osVersion = unknown;

    if (/Windows/.test(os)) {
        osVersion = /Windows (.*)/.exec(os)[1];
        os = 'Windows';
    }

    switch (os) {
        case 'Mac OS X':
            osVersion = /Mac OS X ([\.\_\d]+)/.exec(nAgt)[1];
            break;

        case 'Android':
            osVersion = /Android ([\.\_\d]+)/.exec(nAgt)[1];
            break;

        case 'iOS':
            osVersion = /OS (\d+)_(\d+)_?(\d+)?/.exec(nVer);
            osVersion = osVersion[1] + '.' + osVersion[2] + '.' + (osVersion[3] | 0);
            break;

    }
    var browserInfo = {
        screen: screenSize,
        browser: browser,
        browserVersion: version,
        mobile: mobile,
        os: os,
        osVersion: osVersion,
        cookies: cookieEnabled
    };
    return browserInfo;
}
}
